import React from "react";
import { Form, Input, Select, InputNumber, Switch, Divider, Checkbox, DatePicker, Radio, Space, TimePicker } from "antd";
import { OptionProps } from "antd/es/select";
import { Rule } from "antd/es/form";
import DOMPurify from 'dompurify';
import HtmlRender from "../HtmlRender/HtmlRender";
import UploadFormItem from "./UploadFormItem";
import PhoneFormItem from "./PhoneFormItem";
import CameraFormItem from "./CameraFormItem";
import { CameraFacingMode } from "../../types";
import SignatureFormItem from "./SignatureFormItem";

const FormItem = Form.Item;
const Option = Select.Option;

type RadioValue = { value: string | number, label: string };

export type FormSchema = {
    version: string,
    title: string,
    schemaType: string,
    submissionType: string,
    id: string,
    fields: Field[],
    userIdFieldName: string
}

export type Field = {
    placeholder?: string,
    disabled?: boolean,
    inputType?: string,
    autosize?: boolean,
    min?: number,
    max?: number,
    type: string,
    name: string,
    label: string,
    hint?: string,
    required?: boolean,
    options?: OptionProps[],
    optionId?: string,
    optionLabel?: string,
    initialValue?: string | number | boolean,
    rules?: Rule[],
    content?: string,
    contentUrl?: string,
    title?: string,
    radioValues?: RadioValue[],
    accept?: string,
    uploadOnSubmit?: boolean,
    facingMode?: CameraFacingMode
}

type PropsType = {
    formGroupId?: string,
    fields: Field[]
}

export const getRules = (field: Field): Rule[] => {
    const { required, hint, rules } = field;
    let _rules: Rule[] = [{
        required: required,
        message: hint
    }];
    if (rules) {
        _rules = [..._rules, ...rules];
    }
    return _rules;
};


export default function DynamicForm({
    formGroupId,
    fields
}: PropsType) {

    /**
     * form group to differntiate the forms, allow you to use multiple forms in the same view.
     */
    const getFormGroup = () => {
        return formGroupId ?? new Date().getTime().toString();
    }

    const generateKey = (index: number) => {
        return `${getFormGroup()}_${index}`;
    }

    /////////////// COMPONENTS /////////////////////////

    /*
    {
        type: "input", // field item type.
        name: "name", // field item name.
        label: t("Name"), // field item label.
        hint: t("Please select the end time"),
        required: true,
        placeholder: t("End time of the task"),
        disabled: false,
        initialValue: "ABC",
        inputType: "text"
    }
    */
    const generateInputFormItem = (field: Field) => {
        const { placeholder, disabled, inputType } = field;
        return <Input className="inputfields-radius" size="large" style={{ width: '100%' }} placeholder={placeholder} disabled={disabled} type={inputType || 'text'} />
    }

    /*
    {
        type: "textarea", 
        name: "name", 
        label: t("Name"), 
        hint: t("Please select the end time"),
        required: true,
        placeholder: t("End time of the task"),
        disabled: false,
        initialValue: "ABC",
        autosize: true || { minRows: 2, maxRows: 6 }
    }
    */
    const generateTextAreaFormItem = (field: Field) => {
        const { placeholder, disabled, autosize } = field;
        const _autosize = autosize !== undefined ? autosize : false;
        return <Input.TextArea style={{ width: '100%' }} placeholder={placeholder} disabled={disabled} autoSize={_autosize} />
    }

    /*
    {
        type: "inputnumber", // field item type.
        name: "age", // field item name.
        label: t("Age"), // field item label.
        hint: t("Please select the end time"),
        required: true,
        placeholder: t("End time of the task"),
        disabled: false,
        initialValue: 0,
        min: 0
        max: 120
    }
    */
    const generateInputNumberFormItem = (field: Field) => {
        const { placeholder, disabled, min, max } = field;
        return <InputNumber style={{ width: '100%' }} placeholder={placeholder} disabled={disabled} min={min} max={max} />
    }

    /*
    {
        type: "switch", // field item type.
        name: "switch", // field item name.
        label: t("Checked"), // field item label.
        required: true,
        disabled: false,
        initialValue: true
    }
    */
    const generateSwitchcFormItem = (field: Field) => {
        const { disabled } = field;
        return <Switch disabled={disabled} />;
    }

    const selectFilterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    /*
    {
        type: "select",
        name: "taskTemplate",
        label: t("Task Template"),
        hint: t("Please select a task template"),
        required: true,
        placeholder: t("Select a task template"),
        disabled: false,
        options: tasks, // options
        optionId: "id", // which col refer the id
        optionLabel: "title", // which col refer the label
        initialValue:  undefined
    },
    */
    const generateSelectFormItem = (field: Field) => {
        const { placeholder, disabled, options, optionId, optionLabel } = field;
        if (!options || !optionId || !optionLabel) {
            throw new Error("options, optionId, optionLabel are required.")
        }
        return (
            <Select
                className="selector"
                size="large"
                showSearch
                optionFilterProp="children"
                filterOption={selectFilterOption}
                placeholder={placeholder}
                disabled={disabled}>
                {options.map(i => (
                    <Option key={i[optionId]} value={i[optionId]}>{i[optionLabel]}</Option>)
                )}
            </Select>
        );
    }

    /*
    {
        type: "date", // field item type.
        name: "startTime", // field item name.
        label: t("Start Time"), // field item label.
        hint: t("Please select the start time"),
        required: true,
        placeholder: t("Start time of the task"),
        disabled: false,
        initialValue: dayjs(start),
        mode: "time"
    } */

    const generateDatePickerFormItem = (field: Field, key: number) => {
        const { placeholder, disabled, label, name, required } = field;
        let _rules = getRules(field);
        return (
            <FormItem
                label={label}
                key={generateKey(key)}
                name={name}
                required={required}
                tooltip={placeholder}
                rules={_rules}
            >
                <DatePicker className='inputfields-radius date' size='large' style={{ width: '100%' }} placeholder={placeholder} disabled={disabled} format="YYYY-MM-DD" />
            </FormItem>
        );
    }

    const generateTimePickerFormItem = (field: Field, key: number) => {
        const { placeholder, disabled, label, name, required } = field;
        let _rules = getRules(field);
        return (
            <FormItem
                label={label}
                key={generateKey(key)}
                name={name}
                required={required}
                tooltip={placeholder}
                rules={_rules}
            >
                <TimePicker className='inputfields-radius date' size="large" disabled={disabled} format='h:mm a' />
            </FormItem>
        );
    }

    /*
    {
        type: 'divider',
        label: "Task Details"
    }
    */
    const generateDivider = (field: Field, index: number) => {
        return <Divider key={generateKey(index)} orientation="left"><div dangerouslySetInnerHTML={{ __html: field.label }}></div></Divider>
    }

    /*
    {
        type: 'radio',
    }
    */
    const generateRadioGroupFormItem = (field: Field) => {
        const { radioValues = [] } = field;
        return (
            <Radio.Group>
                <Space direction="vertical" className="radio-btn">
                    {radioValues.map((val: RadioValue) => <Radio key={val.value} value={val.value}> {val.label} </Radio>)}
                </Space>
            </Radio.Group>
        );
    }

    /*
    {
        type: 'radio.button',
    }
    */
    const generateRadioGroupButtonFormItem = (field: Field) => {
        const { radioValues = [] } = field;
        return (
            <Radio.Group>
                <Space direction="horizontal">
                    {radioValues.map((val: RadioValue) => <Radio.Button key={val.value} value={val.value}> {val.label} </Radio.Button>)}
                </Space>
            </Radio.Group>
        );
    }
    ////////////////////////////////////////////////////


    const getFormItem = (field: Field, key: string) => {
        const { type } = field;
        switch (type.toLowerCase()) {
            case "input": return generateInputFormItem(field);
            case "select": return generateSelectFormItem(field);
            case "inputnumber": return generateInputNumberFormItem(field);
            case "switch": return generateSwitchcFormItem(field);
            case "textarea": return generateTextAreaFormItem(field);
            case "radio": return generateRadioGroupFormItem(field);
            case "radio.button": return generateRadioGroupButtonFormItem(field);
            case "camera": return generateCameraFormItem(field, key);
            case "signature": return generateSignatureFormItem(field, key);
            default: return <></>;
        }
    }

    /**
     * general field wrapper. if you want to override this FormItem, add your implementation at generateFormItem
     */
    const generateWrapperedFormItem = (field: Field, index: number) => {
        const { label, name, required, placeholder } = field;
        const key = generateKey(index);
        let _rules = getRules(field);
        const htmlContentLabel = <div dangerouslySetInnerHTML={{ __html: label }}></div>
        return (
            <FormItem
                label={htmlContentLabel}
                key={key}
                name={name}
                required={required}
                tooltip={placeholder}
                rules={_rules}
            >
                {getFormItem(field, key)}
            </FormItem>
        )
    }

    /**
    {
        type: checkbox,
        required: true,
        name: agreed,
        content: agree to <a href="#">agreement</a>
        hint: Should agree to agreement
    }
     */
    const generateCheckbox = (field: Field, index: number) => {
        const key = generateKey(index);
        const { name, required, content, hint } = field;
        let _rules: Rule[] = [];
        if (required) {
            _rules = [{
                validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error(hint)),
            }];
        }
        const cleanContent = DOMPurify.sanitize(content ?? '')
        return (
            <Form.Item
                key={key}
                name={name}
                valuePropName="checked"
                rules={_rules}
            >
                <Checkbox className="checkbox-formitem">
                    <div dangerouslySetInnerHTML={{ __html: cleanContent }}></div>
                </Checkbox>
            </Form.Item>
        );
    }

    /*
    {
        "type": "content",
        "contentUrl": "org1/volunteer/content.html"
    } 
    */
    const generateContent = (field: Field, index: number) => {
        const { contentUrl } = field;
        const key = generateKey(index);
        return <Form.Item key={key} noStyle shouldUpdate>
            <HtmlRender url={contentUrl ?? ''} />
        </Form.Item>
    }

    /*
      {
        "type": "title",
        "title": "<h3>Volunteer EHS Induction Acknowledgement</h3>"
    }
    */
    const generateTitle = (field: Field, index: number) => {
        const { title } = field;
        const key = generateKey(index);
        const cleanContent = DOMPurify.sanitize(title ?? '')
        return <Form.Item key={key}>
            <div dangerouslySetInnerHTML={{ __html: cleanContent }}></div>
        </Form.Item>
    }

    /*
        {
        "type": "phone",
        "name": "mobilePhone",
        "label": "Mobile Phone",
        "required": true,
        "placeholder": "Please enter your mobile phone",
        "hint": "Please enter your mobile phone!"
    }
    */
    const generatePhoneFormItem = (field: Field, index: number) => {
        const key = generateKey(index);
        return <PhoneFormItem field={field} keyId={key} key={key} />;
    }

    /*
        {
        "type": "upload",
        "name": "license",
        "label": "License",
        "required": false,
        "placeholder": "Please upload an image of Driving license",
        "hint": "Please an image of Driving license!",
        "accept": "png,jpg,jpeg",
        "max": 2,
        "uploadOnSubmit": true
    }
    */
    const generateUploadFormItem = (field: Field, index: number) => {
        const key = generateKey(index);
        return (<UploadFormItem key={key} field={field} keyId={key} />);
    }

    /*
        {
        "type": "camera",
        "name": "selfie",
        "label": "Selfie",
        "required": true,
        "placeholder": "Please upload an image of Driving license",
        "hint": "Please an image of Driving license!",
        "max": 2,
        "uploadOnSubmit": true,
        "facingMode": "environment"
    }
    */
    const generateCameraFormItem = (field: Field, key: string) => {
        const { max, facingMode, label } = field;
        return <CameraFormItem key={key} max={max} facingMode={facingMode} title={label} />
    }

    /*
        {
        "type": "signature",
        "required": true,
        "name": "signature",
        "label": "Signature",
        "placeholder": "Please enter your signature!",
        "hint": "Please enter your signature!",
        "uploadOnSubmit": true
    }
    */
    const generateSignatureFormItem = (field: Field, key: string) => {
        const { label } = field
        return <SignatureFormItem key={key} title={label} />;
    }

    const generateFormItem = (field: Field, index: number) => {
        const { type } = field;
        switch (type.toLowerCase()) {
            //  case "custom":
            //      return null; // add your field wrapper implementation here. eg- uploader
            case "divider": return generateDivider(field, index);
            case "checkbox": return generateCheckbox(field, index);
            case "content": return generateContent(field, index);
            case "date": return generateDatePickerFormItem(field, index);
            case "time": return generateTimePickerFormItem(field, index);
            case "title": return generateTitle(field, index);
            case "phone": return generatePhoneFormItem(field, index);
            case "upload": return generateUploadFormItem(field, index);
            default:
                return generateWrapperedFormItem(field, index);
        }
    }
    return (
        <>
            {
                fields.map((field, index) => generateFormItem(field, index))
            }
        </>
    );
}
